import Vue from 'vue';
import {post} from '@/services/api';
import {resendVerification, signin} from '../../services/endpoint.json';
import {setDataStorage} from '@/helpers/localStorage';
import {mapMutations} from 'vuex';
import {decrypt, encrypt} from '@/helpers/encrypt';
import {isUndefined} from '@/utils';
import {UserRole} from "@/utils/auth.roles";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'Login',
  mixins: [show_alert_mixin],
  data: () => ({
    email: '',
    password: '',
    dash: 'dashboard',
    verifiedEmail: false,
    userId: '',
    term: 0,
    to: null,
    isCompanyEmpty: true,
  }),
  methods: {
    ...mapMutations(['loaderManager', 'userNameManager']),
    async login() {
      this.loaderManager(true);
      post(`auth/${signin}`, {
        email: this.email,
        password: encrypt(this.password),
      })
        .then(async (response) => {
          await localStorage.removeItem('showSwitchCurrency');
          await localStorage.removeItem('showCurrencyLocal');
          const data = response.data;
          const showSwitchCurrency = (data.showCurrency === null) ? true : data.showCurrency.showSwitch
          const showCurrencyLocal = (data.showCurrency === null) ? false : data.showCurrency.showLocal
          this.term = data.term;
          this.isCompanyEmpty = data.isCompanyEmpty
          this.userNameManager(data.name);
          this.$store.commit("setIsCompanyEmpty", data.isCompanyEmpty)
          await localStorage.setItem('admin-type', data.type);
          await localStorage.setItem('showSwitchCurrency', showSwitchCurrency);
          await localStorage.setItem('showCurrencyLocal', showCurrencyLocal);
          await setDataStorage('admin-token', data.token);
          await setDataStorage('admin-id', data.id);
          await setDataStorage('admin-name', data.name);
          await setDataStorage('admin-term', data.term);
          await setDataStorage('admin-company', data.isCompanyEmpty);
          this.$store.commit('subUserPermission/setSubUserPermissions', data.permissions)
          this.redirectDashboard(decrypt(data.type));
          this.$socket.emit('identity', data.id);
          this.$store.commit('setUser', {
            id: (data.id),
            email: this.email,
            role: JSON.parse(decrypt(data.type))[0],
            country_id: data.country_id,
            name: data.name,
            twoFactor: data.twoFactor,
            twoFactorPass: false,
            subUser: data.subUser
          });

          if (data.twoFactor) {
            await this.$router.push('two-factor')
          }
          else if (data.id == 2) {
            this.$router.push({name: "information", params: {company: data.name}}).then().catch();
          }
          else if (this.to !== undefined) {
            this.$router.push(this.to).then().catch();
          } else if (data.isCompanyEmpty === true) {
            this.$router.push({name: 'required-information'}).then().catch();
          } else {
            this.$router.push({name: this.dash}).then().catch();
          }
          this.loaderManager(false);
          this.openSocketConnection(data.token);
        })
        .catch((err) => {
          if (!isUndefined(err.response)) {
            if (err.response.status === 433) {
              this.showAlertNotification(this.$t('login.credentials_invalid'), 'error')
            }
            if (err.response.status === 422) {
              this.showAlertNotification(this.$t('login.user_no_found'), 'error')
            } else if (err.response.status === 400) {
              this.showAlertNotification(this.$t('login.email_not_verified'), 'error')
              this.verifiedEmail = true;
              this.userId = err.response.data.id;
            } else if (err.response.status === 406) {
              this.showAlertNotification(this.$t('login.user_not_verified'), 'error')
            } else if (err.response.status === 401) {
              this.$swal({
                showCancelButton: false,
                showConfirmButton: true,
                icon: 'error',
                title: this.$t('login.user_incomplete'),
                confirmButtonColor: '#d14343',
                cancelButtonColor: '#000000',
                cancelButtonText: 'No',
                confirmButtonText: 'OK',
              }).then(async res => {
                if (res.isConfirmed) {
                  window.location.href = err.response.data.url
                }
              });
            } else if (err.response.status === 450) {
              this.showAlertNotification(err.response.data.message, 'error')
            }
          }
        });
    },
    validate() {
      return this.email !== '' && this.password !== '';
    },
    openSocketConnection(token) {
      this.$socket.io.opts.query = {
        token: token
      };
      this.$socket.open();
    },
    async resendEmail() {
      this.verifiedEmail = false;
      if (this.userId !== '') {
        const {data} = await post(resendVerification, {
          type: 'email',
          id: this.userId,
        });
        if (Number(data.statusCode) === 200) {
          this.showAlertNotification('We have sent a validation email')
          this.verifiedEmail = false;
        }
      }
    },
    redirectDashboard(rol) {
      if (rol.includes('support')) {
        this.dash = 'support';
      } else if (rol.includes(UserRole.Distributor.toString()) || rol.includes(UserRole.Dealer.toString()) || rol.includes(UserRole.SubDealer.toString())) {
        if (Number(this.term) === 0) {
          this.dash = 'required-information';
        }
      }
      return this.dash;
    },
  },
  mounted() {
    this.to = this.$route.query.to;
    if (this.to === 'credit-order-detail') {
      let offer = JSON.parse(decrypt(this.$route.query.offer));
      this.to = {name: 'credit-order-detail', params: {id: offer.id}}
      this.$store.dispatch('notification/add', {
        id: offer.id,
        title: 'You have a new offer!',
        text: `Click here to view details`,
        type: 'OfferOrder',
        time: new Date(),
        url: this.to,
      })
    }
  }
});
